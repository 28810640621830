import React, { useEffect, useState } from "react";
import styles from "./splash.module.css";

import { IntegrationDetail } from "../../../models/topic-detail.entity";
import Button from "../../../components/Button/Button";
import { useStyles } from "../../../../providers/styles/use-styles";

interface Props {
  gameInfo: IntegrationDetail;
  onStarted: () => void;
}

const Splash = ({ gameInfo, onStarted }: Props) => {
  const [loading, setLoading] = useState(false);
  const { getStyles } = useStyles();

  return (
    <div className={styles.wrapper}>
      <h3
        className={styles.title}
        style={getStyles(["txt_label_primary_base"])}
      >
        {gameInfo.name}
      </h3>
      <p
        className={styles.description}
        style={getStyles(["txt_label_secondary_base"])}
      >
        {gameInfo.description}
      </p>
      <img
        className={styles.icon}
        src={gameInfo.sponsor.logo_url}
        alt={gameInfo.sponsor.name}
        height={40}
      />
      <img
        className={styles.image}
        src={gameInfo.image_url}
        alt={gameInfo.name}
      />

      <div className={styles.spacer} />

      <Button
        title="ИГРАТЬ"
        onClick={() => {
          setLoading(true);
          onStarted();
        }}
        isLoading={loading}
      />
    </div>
  );
};

export default Splash;
