import React from "react";

import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import GameScreen from "./app/modules/GameScreen/GameScreen";
import NotFoundScreen from "./app/modules/NotFoundScreen/NotFoundScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { retrieveLaunchParams, subscribe } from "@telegram-apps/sdk"
import { parse, User } from '@telegram-apps/init-data-node'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/integrations/:integration/" element={<GameScreen />} />

        <Route path="/" element={<Home />} />

        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tgWebAppStartParam = queryParams.get('tgWebAppStartParam');

    if (tgWebAppStartParam) {
      navigate(`/integrations/${tgWebAppStartParam}`);
    }
  }, [location, navigate]);

  subscribe(() => { })

  return <div><NotFoundScreen /></div>;
};


const Telegram = () => {
  try {
    const initParams = retrieveLaunchParams();
    // const user = parse(initParams).user;
    const user = {
      id: "1223",
      username: "test"
    }

    if (user !== undefined) {
      return (
        <>
          {
            user && (
              <div>
                <div>
                  id: {initParams.initDataRaw}
                </div>
                <div>
                  telegramId: {user.id}
                </div>
                <div>
                  username: {user.username}
                </div>
              </div>
            )
          }
        </>
      )
    }

    return <NotFoundScreen />
  }
  catch (e) {
    return <NotFoundScreen />
  }
}

export default App;
