import React, { useEffect, useMemo, useState } from "react";
import styles from "./results.module.css";
import { QuestionResult } from "../../../models/game-result.entity";
import { useApiClient } from "../../../../providers/api-client";
import Button from "../../../components/Button/Button";
import cupLogo from "../../../../assets/images/cup-silver-gold-300.svg";
import { ReactComponent as Valid } from "../../../../assets/images/valid.svg";
import { ReactComponent as Invalid } from "../../../../assets/images/invalid.svg";

import { toast } from "react-toastify";
import { useStyles } from "../../../../providers/styles/use-styles";

interface Props {
  token: string;
  integrationId: string;
}

interface ContactLink {
  email?: string
}

const Results = ({ token, integrationId }: Props) => {
  const client = useApiClient();
  const { getStyles } = useStyles();

  const [results, setResults] = useState<QuestionResult[]>();
  const [showDetails, setShowDetails] = useState(false);
  const [isContactAvailable, setContactAvailable] = useState(true);
  const [contactLink, setContactLink] = useState<ContactLink>();
  const [email, setEmail] = useState<string>();
  const [savingEmail, setSavingEmail] = useState(false);

  function setContactEmail(email: string) {
    setEmail(email);
  }

  function submitEmail() {
    if (email) {
      const link: ContactLink = {
        email: email
      }
      setSavingEmail(true);
      client
        .postContactInfo(token, email)
        .then((result) => {
          setContactLink(link);
        })
        .catch((error: any) => {
          console.log(error);

          toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
            position: "top-center",
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        })
        .finally(() => {
          setSavingEmail(false);
        });
    }
  }

  useEffect(() => {
    client
      .getResults(token, integrationId)
      .then((result) => {
        setResults(result.results);
        const link: ContactLink = {
          email: result.contact?.email
        }
        setContactLink(link);
        setContactAvailable(result.is_contact_needed);
      })
      .catch((error: any) => {
        console.log(error);

        toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
          position: "top-center",
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      });
  }, []);

  const correctResults = useMemo(() => {
    return results?.filter((r) => r.answer.is_correct).length ?? 0;
  }, [results]);

  return (
    <div className={styles.wrapper}>
      <h3
        className={styles.title}
        style={getStyles(["txt_label_primary_base"])}
      >
        Результаты
      </h3>

      {!showDetails ? (
        <>
          <img
            className={styles.image}
            src={cupLogo}
            width={100}
            height={100}
            alt="лого"
          />
          <p
            className={styles.description}
            style={getStyles(["txt_label_secondary_base"])}
          >
            Ваш результат
          </p>
          {results ? (
            <p
              className={styles.result}
              style={getStyles(["txt_label_primary_base"])}
            >
              <span style={getStyles(["txt_label_primary_success"])}>
                {correctResults}
              </span>{" "}
              / {results.length}
            </p>
          ) : (
            <img
              className={styles.spinner}
              src="/images/circular-indeterminate-thin.svg"
              width={48}
              height={48}
              alt="загрузка"
            />
          )}

          <div className={styles.spacer} />

          {isContactAvailable && (
            <div>
              {contactLink?.email ? (
                <div className={styles.inputWrapper}>
                  <p
                    className={styles.description}
                    style={getStyles(["txt_label_secondary_base"])}
                  >
                    Вы указали email для связи в случае выигрыша
                  </p>
                  <input
                    className={styles.input}
                    style={getStyles([
                      "txt_chips_primary_base",
                      "border_chips_primary_base",
                    ])}
                    value={contactLink.email}
                    disabled={true}
                  />
                </div>
              ) : (
                <div className={styles.inputWrapper}>
                  <p
                    className={styles.description}
                    style={getStyles(["txt_label_secondary_base"])}
                  >
                    Укажите email, чтобы мы могли связаться с вами в случае выигрыша
                  </p>
                  <input
                    className={styles.input}
                    style={getStyles([
                      "txt_chips_primary_base",
                      "border_chips_primary_base",
                    ])}
                    type="email"
                    placeholder="Укажите email для связи"
                    disabled={savingEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                    maxLength={40}
                  />
                  <Button title="ОТПРАВИТЬ" isLoading={savingEmail} onClick={submitEmail} />
                </div>
              )}
            </div>
          )}

          <div className={styles.spacer} />

          {results && (
            <div className={styles.actionWrapper}>
              <Button title="ДЕТАЛИ" onClick={() => setShowDetails(true)} />
            </div>
          )}
        </>
      ) : (
        <div className={styles.answers}>
          {results?.map((result, position) => (
            <div
              className={styles.answer}
              style={getStyles(["bg_cell_primary_base"])}
            >
              <span
                className={styles.answerPosition}
                style={getStyles(["txt_cell_primary_base"])}
              >
                {position + 1}
              </span>
              <span
                className={styles.answerText}
                style={getStyles(["txt_cell_primary_base"])}
              >
                {result.question.text}
              </span>

              <div
                className={styles.answerImage}
                style={getStyles([
                  result.answer.is_correct
                    ? "bg_icon_primary_success"
                    : "bg_icon_primary_error",
                ])}
              />

              <div
                className={styles.answerIcon}
              >
                {result.answer.is_correct ? <Valid /> : <Invalid />}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Results;
