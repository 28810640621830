import { useState } from "react"
import { ApiClient } from './api-client';
import { ApiClientContext } from './api-client-context';

type Props = {
  client?: ApiClient;
  children: React.ReactNode;
};

export const ApiClientProvider = (props: Props) => {
  const [client] = useState(props.client || new ApiClient());

  return (
    <ApiClientContext.Provider value={client}>
      {props.children}
    </ApiClientContext.Provider>
  );
};
